export const enum LoginMode {
  'AUTH_EMAIL' = 'AUTH_EMAIL',
  'AUTH_TELEGRAM' = 'AUTH_TELEGRAM',
  'REGISTER_EMAIL' = 'REGISTER_EMAIL',
}

export const enum LoginScreenContext {
  'menu' = 'menu',
  'order' = 'order',
  'null' = 'null',
}
