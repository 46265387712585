<template>
  <div class="justify-align-center col-gap-3 auth-form">
    <UIField ref="login" :field="emailField" />
    <template v-if="mode === LoginMode.AUTH_EMAIL"
      ><UIField ref="password" :field="passwordField" />
      <form @submit.prevent="authorize">
        <UIButtonPrimary class="button-lg" :disabled="authorizeDisabled">Отправить</UIButtonPrimary>
      </form>
    </template>
    <form v-else-if="mode === LoginMode.REGISTER_EMAIL" @submit.prevent="register">
      <UIButtonPrimary class="button-lg" :disabled="registerDisabled">Отправить</UIButtonPrimary>
    </form>
    <span>или</span>
    <LoginTelegramAuthorize :context="context" button="secondary" @authorized="$emit('authorized')">
      Войти через Telegram
    </LoginTelegramAuthorize>
  </div>
</template>

<script setup lang="ts">
import { passwordField } from '~/bin/publisher/payments';
import { emailField } from '~/bin/publisher/payments/credentials/templates';
import { successMessage } from '~/common/feedback';
import { LoginMode, LoginScreenContext } from '~/interfaces/login/UI';
import type { UIFieldExpose } from '~/interfaces/publisher/UI';
import AuthService from '~/services/AuthService';
/* eslint-disable no-undef */

interface Props {
  mode: LoginMode;
  context: LoginScreenContext;
}

const props = defineProps<Props>();
const emits = defineEmits(['authorized']);

const login = ref<UIFieldExpose>();

const password = ref<UIFieldExpose>();

const registerDisabled = computed(() => !unref(login)?.valid);
const authorizeDisabled = computed(() => [login, password].some((field) => !unref(field)?.valid));

const authorize = async () => {
  try {
    await AuthService.authorize({
      login: unref(login)?.value as string,
      password: unref(password)?.value as string,
    });
    if (props.context === LoginScreenContext.null) {
      navigateTo('/orders');
    } else {
      emits('authorized');
    }
  } catch (error) {
    console.log(error);
  }
};

const register = async () => {
  try {
    await AuthService.register(unref(login)?.value ?? '');
    successMessage('Письмо с подтверждением email отправлено вам на почту!');
  } catch (error) {
    console.log(error);
  }
};
</script>
